import format from "string-format";

export const pageUtils = {
    getRoute: function (keyPath = "") {
        const keys = keyPath.split(".")
        let current = this
        keys.forEach(key => {
            if (current.child[key]) {
                current = current.child[key]
            } else {
                return null
            }
        })
        return current.route
    },
    getPath: function (keyPath = "", params = {}) {
        const keys = keyPath.split(".");
        let current = this
        keys.forEach(key => {
            if (current.child[key]) {
                current = current.child[key]
            } else {
                return null
            }
        });
        return format(current.path, params)
    },
    getRegex: function (keyPath = "") {
        const keys = keyPath.split(".")
        let current = this
        keys.forEach(key => {
            if (current.child[key]) {
                current = current.child[key]
            } else {
                return null
            }
        })
        return current.regex
    },
    getRegexFollow: function (keyPath = "") {
        const keys = keyPath.split(".")
        let current = this
        keys.forEach(key => {
            if (current.child[key]) {
                current = current.child[key]
            } else {
                return null
            }
        })
        return current.regex_follow
    },

    // pages info
    key: "",
    route: "/*",
    path: "/",
    regex: /^\/$/,
    regex_follow: /^.*$/,
    child: {
        dashboard: {
            key: "dashboard",
            route: "/dashboard/*",
            path: "/dashboard",
            regex: /^\/dashboard?$/,
            regex_follow: /^\/dashboard.*?$/,
            child: {},
        },
        login: {
            key: "login",
            route: "/login/*",
            path: "/login",
            regex: /^\/login?$/,
            regex_follow: /^\/login.*?$/,
            child: {},
        },
        orgs: {
            key: "orgs",
            route: "/orgs/*",
            path: "/orgs",
            regex: /^\/orgs?$/,
            regex_follow: /^\/orgs.*?$/,
            child: {
                id: {
                    key: "id",
                    route: "/:id/*",
                    path: "/orgs/{id}",
                    regex: /^\/orgs\/[\w\-]+?$/,
                    regex_follow: /^\/orgs\/[\w\-].*?$/,
                    child: {
                        general: {
                            key: "general",
                            route: "/general/*",
                            path: "/orgs/{id}/general",
                            regex: /^\/orgs\/[\w\-]+\/general?$/,
                            regex_follow: /^\/orgs\/[\w\-]+\/general.*?$/,
                            child: {}
                        },
                        themes: {
                            key: "themes",
                            route: "/themes/*",
                            path: "/orgs/{id}/themes",
                            regex: /^\/orgs\/[\w\-]+\/themes?$/,
                            regex_follow: /^\/orgs\/[\w\-]+\/themes.*?$/,
                            child: {}
                        }
                    }
                }
            },
        },
        settings: {
            key: "settings",
            route: "/settings/*",
            path: "/settings",
            regex: /^\/settings?$/,
            regex_follow: /^\/settings.*?$/,
            child: {
                account: {
                    key: "account",
                    route: "/account/*",
                    path: "/settings/account",
                    regex: /^\/settings\/account?$/,
                    regex_follow: /^\/settings\/account.*?$/,
                    child: {}
                }
            },
        },
        users: {
            key: "users",
            route: "/users/*",
            path: "/users",
            regex: /^\/users?$/,
            regex_follow: /^\/users.*?$/,
            child: {
                id: {
                    key: "id",
                    route: "/:id/*",
                    path: "/users/{id}",
                    regex: /^\/users\/[\w\-]+?$/,
                    regex_follow: /^\/users\/[\w\-].*?$/,
                    child: {}
                }
            },
        },
    }
}