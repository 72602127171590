import { lazy, memo } from "react";
import { Routes, Route } from "react-router-dom";

import { pageUtils } from "utils/page.utils";

const RootLayout = lazy(() => import("./layout"));

const PrivateRouter = lazy(() => import("./(private)/router"));

const LoginPage = lazy(() => import("./(public)/login"))

const RootRouter = memo(() => {
    return (
        <Routes>
            <Route element={<RootLayout />}>
                <Route
                    exact
                    path={pageUtils.getRoute("login")}
                    element={<LoginPage />}
                />
                <Route
                    path={"*"}
                    element={<PrivateRouter />}
                />
            </Route>
        </Routes>
    )
})

export default RootRouter