import './styles/global.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { pageUtils } from "utils/page.utils"

import RootRouter from 'app/router';

const App = () => {
  console.log("Router")

  return (
    <BrowserRouter>
      <Routes>
        <Route path={pageUtils.getRoute()} element={<RootRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;